.page-active {
    background-color: #0973c5;
    background: #0973c5;
    color: #ffffff;
}

.page-active:focus {
    background-color: #0973c5;
    background: #0973c5;
    color: #ffffff;
}

.num-pages {
    margin-top: -6px;
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-align: center;
}

.prev-pag {
    width: 28px;
    height: 31px;
    margin: -7px 20px 25px 19px;
    padding: 6px 3px 0px 1px;
    border-radius: 3px;
}

.next-pag {
    width: 28px;
    font-size: 0.1rem;
    height: 31px;
    margin: -7px 3px;
    padding: 6px 3px 0px 1px;
    border-radius: 3px;
}

.pi-ellipsis-h {
    margin: 18px 10px;
}