.form-container h1 {
  color: rgb(255, 255, 255);
  font-family: 'Philosopher', sans-serif;
}


.form-zip{
  margin: 0 auto;
  transition: 0.3s;
  padding: 40px;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
}

.form-zip input {
  border: 1px solid #7a8c99;
  border-radius: 5px;
  font-size: 1rem;
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  font-family: 'Open Sans', sans-serif;
}

.form-zip .input-required {
  border: 1px solid #ff0000;
  outline: #ff0000;
  font-family: 'Open Sans', sans-serif;
}

.form-zip p {
  color: #5a5a5a;
  text-align: justify;
  font-family: 'Open Sans', sans-serif;
}

.form-zip span {
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}

.form-zip .planes {
  background-color: #133155;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
}

.form-zip .planes:hover {
  background-color: #133155;
  font-family: 'Open Sans', sans-serif;
}

.form-zip .error-section {
  background-color: #d70404;
  color: #ffffff;
  padding: 1px;
  font-family: 'Open Sans', sans-serif;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #133155 !important;
  border-color: #133155 !important;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
}
.btn-outline-primary {
  --bs-btn-color: #133155;
  --bs-btn-border-color: #133155;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #133155;
  --bs-btn-hover-border-color: #133155;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #133155;
  --bs-btn-active-border-color: #133155;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #133155;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #133155;
  --bs-gradient: none;
  font-family: 'Open Sans', sans-serif;
}

.form-header{
   padding-bottom: 80px;
}

.bg-form{
  background-image: url('../src/assets/images/fondo1.jpg');
    /* Full height */
    height: 100vh; 

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}