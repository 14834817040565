.lenguaje-selector{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;   
    color:#fff;
    text-transform: uppercase;
    padding: 0px
    
    
}

.header-lan{
    background-color: #c12b2b00;
 
   
}


.p-selectbutton .p-button.p-highlight {
    background: #104566;
    border-color: #2196F3;
    color: #ffffff;
}

.top-bar{
    background-color: #c12b2b;
    padding: 0 !important;
    margin: 0;
    display: flex;   
  
    justify-content: center;
    height: 30px;
}

@media (max-width: 800px){
    .top-bar{
        background-color: #c12b2b;
        padding: 0 !important;
        margin: 0;
        display: flex;
        flex-direction: column;   
      
        justify-content: center;
        text-align: center;
        height: auto !important;
      
    }

    .top-bar-text{
        color: #fff;
        text-align: center !important;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        padding-right: 50px;
    }

    .header-lan {
        background-color: #c12b2b00;
        padding-bottom: 0px;
    }

    .mt-5 {
        margin-top: 1rem !important;
    }

}


.top-bar-text{
    color: #fff;
    text-align: right;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    padding-right: 50px;
}

.container-fluid{
    --bs-gutter-x: 0rem;
}

a{
    color: #fff;
    text-decoration: none;
}