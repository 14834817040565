@import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body{

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 100%;  
}

.size-t{
    font-size: 14px !important;
    color: #133155;
    font-family: 'Open Sans', sans-serif;
    
}

.disclaimer{
    font-size: 14px;    
}

h1,h2
h3,
h4,h5,h6 {
    color: #133155;
    font-family: 'Open Sans', sans-serif;
}

.bg-color-quoter{
    background-color: #F2F2F2;      
    
}

.sub-title{
    font-size: 16px;
}   
.flex-container{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-direction: row ;
    padding-left: 10%;
    padding-right: 10%;
    background: #fff;
    border-radius: 0px;
    /*box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);*/
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 0px solid rgba(150, 147, 150, 0.4); 
}

.flex-container2{
    display: flex;   
    flex-direction: row ;  
     
}
.flex-containercard{
    display: flex;   
    flex-direction: row ;  
     
}

.p-dropdown   {
    color: #6c757d;
    background-color: transparent;
}


.p-dropdown-panel  {
    background: rgba(255, 255, 255, 0.2);

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
 
}
.p-dropdown-header {
    padding: 0.5rem 1rem;
    border-bottom: 0 none;
    color: #495057;
    background: transparent;
    margin: 0;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}


.logo-yti{
    width: 100%;
    max-width: 150px;
}

.p-inputtext {
    margin: 0;
    background-color: #e3e3e3;
    border-radius: 0px !important;
}

.p-dropdown .p-dropdown-label {
    background: #e3e3e3;
    border: 0 none;
}

.p-dropdown .p-dropdown-trigger {
    background: #e3e3e3;
    color: #0d0d0d;
    width: 2.357rem;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.p-dropdown .p-dropdown-label.p-placeholder {
    color: #000000;
    font-family: 'Open Sans', sans-serif;
}
.p-button{
    border-radius: 0px !important;
    max-width: 250px !important;
}
.card{
    background-color:transparent;
    border: 0;
}

p{
    font-family: 'Open Sans', sans-serif;
}

@media (max-width: 800px) {
    .flex-container{
        
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
        border-radius: 5px;
        padding: 10px;
        border: 1px solid rgba(150, 147, 150, 0.4);
    
    }
    .flex-containercard{
        
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    
    }



    .p-button.p-button-info{
        width: 100% !important;
     
        font-size: 12px;
 
    
    
    }
    .p-button2{
        border-radius: 0px !important;
        max-width:100% !important;
    }
    .pre-footer{
        font-size:10px ;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    
    }

    .contenedor-eligibility{
        padding-top: 50px;
    }
    .contenedor-quoter{
        width: 100% !important;
    }
    .col-8 {
        flex: 0 0 auto;
        width: 100% !important;
    }
    .col-6 {
       
        width: 100%;
        
    }

    .offset-4 {
        margin-left: 0%;
    }

    
    .col-4 {
        flex: 0 0 auto;
        width: 100%;
    }

    
    .hide-mobile{
        display: none;
    }

    .col-9 {
        flex: 0 0 auto;
        width: 100% !important;
    }

    .col-3 {
        flex: 0 0 auto;
        width: 100% !important;
        text-align: center;
    }

    .col-2 {
        
        width: 100% !important;
        max-width: 10%;
        margin: auto;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .contenedor-eligibility{
        padding-left:0 !important;
    }
    .information-container {
        
        border-right: 0px solid #a6d7fb !important;
    }

    .p-dropdown {
        color: #6c757d;
        background-color: transparent;
        width: 100%;
    }

    .p-inputnumber {
        display: inline-flex;
        width: 100% !important;
    }

    .footer-card{
        padding: 10px 0 10px 0;
        background-color: #d7b7b0;
        border-radius: 0 0 30px 30px;
        text-align: center;
    }
    .fw-bold {
        font-weight: 700 !important;
        font-size: 14px;
    }
    
    
}

.information-container {
    padding-right: 5%;
    border-right: 1px dashed #dddede;
}

.label-checks {
    margin-left: 10px;
    text-decoration: underline dotted #133155;
}

.card-pistache {
    background-color: rgb(237, 255, 253);
    border-color: #39AAE4;
}

.card-blue-light {
    background-color: #f2faff;
    border-color: #133155;
    font-size: 0.8rem;
}

.card-red-light {
    background-color: #fff5f3;
    border-color: #f5897a;
    font-size: 0.8rem;
}

.card-pistache h5 {
    font-weight: 700;
    color: #39AAE4;
}

.card-pistache span {
    font-weight: 600;
    font-size: 10px;
}

.chip {
    font-size: 0.8rem;
    font-weight: 600;
    color: #39AAE4;
}

.form-invalid {
    border: 1px solid #ff0000;
}

.error-section {
    background-color: #d70404;
    color: #ffffff;
    padding: 1px;
}

.btn-outline-primary {
    --bs-btn-color: #133155;
    --bs-btn-border-color: #133155;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #133155;
    --bs-btn-hover-border-color: #133155;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #133155;
    --bs-btn-active-border-color: #133155;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #133155;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #133155;
    --bs-gradient: none;
    border-radius: 0px;
    background-color: #114566;
    color: #Fff;
  }

  .p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button, .p-fileupload-choose.p-button-info {
    color: #ffffff;
    background: #c12b2b;
    border: 1px solid #c12b2b;
    font-family: 'Open Sans', sans-serif;
}
.p-button-info2{
    color: #000;
    background: #fff;
    border: 1px solid #39AAE4;
    font-family: 'Open Sans', sans-serif; 
}

.p-button-info3{
    color: #000;
    background: #DEDFE0;
    border: 1px solid #303030;
    font-family: 'Open Sans', sans-serif; 
}

.premium-cost{
    color:#39AAE4;
    font-size: 14px;

}

.deductible{
    color: #bfa6fb;

}

.moops{
    color: #d70404;
}
.cost-card{
    justify-content: center;
    text-align: center;
    padding: 10px 0 10px 0
}

.title-card{
    text-align: center;
    padding: 10px 0 10px 0;
}



.bg-header{
    background-color: #fff;
    border-radius: 30px 30px  0 0;
}
.under-line{
    border-bottom: 1px dashed #417ba4;
    padding-bottom: 1px;
}

.pre-footer{
    padding: 10px 0 10px 0 ;
}

.footer-card{
    padding: 10px 0 10px 0;
    background-color: #114566 !important;
    border-radius: 0 0 30px 30px;
    text-align: center;
}
.contenedor-plan{
    border: solid 1px;
}

.row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    background-color: #fff;
}

.container{
    padding-bottom: 20px;
   
    
}

.contenedor-eligibility{
    padding-left:3% !important;
}


.glass{
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.2);
border-radius: 30px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(13.5px);
-webkit-backdrop-filter: blur(13.5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.p-dialog .p-dialog-content {
    background: #ffffff00;
    color: #fff;
    padding: 0 1.5rem 2rem 1.5rem;
}

.p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #a6d7fb;
    color: #495057;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    text-align: center;
    border-radius: 30px 30px 0px 0px ;
}
.p-dialog .p-dialog-footer {
    border-bottom: 0 none;
    background: #d7b7b0;
    color: #fff;
    padding: 1.5rem;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    text-align: center;
    border-radius: 0px 0px 30px 30px ;
}

.logos-carrier{
    
    width: 100%;
    margin: auto;
}

.logos-carrier-img{
    max-width: 100px;
}

.header-info{
    background-color: #114566;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.title_header{
    font-family: 'Philosopher', sans-serif;
    color: #fff !important;
}

.title_header_e{
    font-family: 'Philosopher', sans-serif !important;
    color: #114566
    
}

.Eligibility-header{
    background-color: #fff !important;
}

.tarjeta-savings{
    background-color: #114566;
    width: 400px;
    color:#fff;
    display: flex;
    flex-direction: inherit;
    padding: 20px;
    justify-content: space-between;
    
}

.form-bg{

 background-image: url("../../../assets/images/ImagenFondoCot-Azul.jpeg");

 /* Full height */
 height: 100%; 

 /* Center and scale the image nicely */
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 height: 100vh;


 
}

.bgImage{
    background-image: url("../../../assets/images/ImagenFondoCot-Azul.jpeg");

  /* Full height */
  height: 100%; 

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.footer{
    background-color:  #114566;
    padding-right: 50px;
    padding-left: 50px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    bottom: 0;
    align-items: baseline;

    
}

.iconos{
    font-size: 30px !important;
}

.dependientes{
    background-color: rgb(252, 197, 197);
}

.card-found{
    background-color:#c12b2b ;
   
    
}

.title_header_found{
    font-family: 'Philosopher', sans-serif !important;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    
}

.header-bar{
    padding-left: 50px;
    padding-right: 50px;
}

.col-separator{
    border-right: solid 1px #c3c3c3 !important;
    
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
    padding-left: 20px !important;
}

.p-inputnumber-buttons-horizontal .p-inputnumber-input {
    order: 2;
    border-radius: 0;
    width: 50px;
  }
